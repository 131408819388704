@font-face {
  font-family: 'Brandon-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('../assets/fonts/Brandon-Regular.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Brandon-Medium';
  font-weight: normal;
  font-style: normal;
  src: url('../assets/fonts/Brandon-Medium.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Brandon-Bold';
  font-weight: normal;
  font-style: normal;
  src: url('../assets/fonts/Brandon-Bold.otf') format('opentype');
  font-display: swap;
}

@font-face {
  font-family: 'Foco-Regular';
  font-weight: normal;
  font-style: normal;
  src: url('../assets/fonts/Foco-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Foco-Bold';
  font-weight: normal;
  font-style: normal;
  src: url('../assets/fonts/Foco-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Foco-Black';
  font-weight: normal;
  font-style: normal;
  src: url('../assets/fonts/Foco-Black.ttf') format('truetype');
  font-display: swap;
}
